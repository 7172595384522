import React from 'react'
// Types
import { SectionBaseProps, SectionFeaturesList } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Heading } from '../../../atoms'
import FeaturesListItem from './FeaturesListItem'

export interface FeaturesListProps extends SectionBaseProps {
  data: SectionFeaturesList
}

const FeaturesList: React.FC<FeaturesListProps> = ({ data }) => {
  const { id, sectionId, headline, centeredHeadline, items, whiteContent, backgroundColor } = data
  return (
    <Box
      as={'section'}
      id={sectionId || id}
      className={'p-section'}
      py={backgroundColor ? [80, 80, 120] : undefined}
      my={!backgroundColor ? [80, 80, 120] : undefined}
      backgroundColor={backgroundColor?.hex}
    >
      <Grid>
        <Box mb={[60, 70, 80]}>
          <Row center={centeredHeadline ? 'xs' : undefined}>
            <Col xs={12} md={10} lg={centeredHeadline ? 8 : 10}>
              <Heading
                as={'h3'}
                textAlign={centeredHeadline ? 'center' : 'left'}
                color={whiteContent ? 'white' : 'dark'}
              >
                {headline}
              </Heading>
            </Col>
          </Row>
        </Box>
        {items && items.length > 0 && (
          <Box>
            <Row>
              {items.map((item) => (
                <Col key={item.id} xs={12} md={6}>
                  <Box mb={[7, 8, 9]}>
                    <FeaturesListItem {...item} light={whiteContent} />
                  </Box>
                </Col>
              ))}
            </Row>
          </Box>
        )}
      </Grid>
    </Box>
  )
}

export default FeaturesList
