import React from 'react'
import { FeatureItemWithIconProps } from '../../../../types/items'
import { Box, Flex, Image, Text } from '../../../../atoms'

type FeaturesListItemProps = FeatureItemWithIconProps & { light?: boolean }

const FeaturesListItem: React.FC<FeaturesListItemProps> = ({ name, content, asset, light = false }) => {
  return (
    <Flex>
      <Box flex={'0 0 56px'} mr={5}>
        <Image draggable={false} alt={asset.alt || name} image={asset.gatsbyImageData} />
      </Box>
      <Box flex={'1'} pt={3} pr={[0, 0, 4, 7]}>
        <Box>
          <Text as={'h5'} fontSize={4} fontWeight={'semi'} color={light ? 'white' : 'dark'}>
            {name}
          </Text>
        </Box>
        {!!content && (
          <Box mt={4}>
            <Text fontSize={2} whiteSpace={'pre-line'} color={light ? 'white' : 'dark'}>
              {content}
            </Text>
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export default FeaturesListItem
